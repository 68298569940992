import type {SiteConfig} from '~/interfaces/multisite';

const PatientsConfig = {
    name: 'Reliable information on urology - Patient Information',
    handle: 'patients',
    gtmId: 'GTM-PTGPWZ2',
    group: 'patients',
    locale: 'en-US',
    language: 'English (EN)',
    seo: {
        prepend: '- Patient Information',
        description: 'EAU Patient Information is your portal to reliable information on urology and urology health: urologic cancers, other diseases, tests and treatments.',
    },
    enable: [
        'hero-split-layout',
        'home-selected-searches',
        'home-links-list',
        'home-chatbot',
        'home-background-color',
        'lang-switch',
        // Specific Feature modifiers
        'nav-enhanced-search',
        'nav-logo-centered-on-home',
        'nav-hide-search-on-home',
        'nav-hide-search-on-search',
        'show-visited-links'
    ],
    // TODO: temporarily disable indexing, remove before launch 🚀
    tags: [{
        name: 'robots',
        content: 'noindex'
    }]
} as SiteConfig;

const PatientsConfigNl = {
    ...PatientsConfig,
    name: 'Betrouwbare informatie over urologie - Patiënten informatie',
    handle: 'patientsNl',
    group: 'patients',
    locale: 'nl',
    language: 'Nederlands (NL)',
    seo: {
        prepend: '- Patiëntinformatie',
        description: 'EAU Patiëntinformatie is uw portal naar betrouwbare informatie over urologie en urologische gezondheid: urologische kankers, andere ziekten, tests en behandelingen.',
    },
} as SiteConfig;

const PatientsConfigEs = {
    ...PatientsConfig,
    name: 'Información confiable sobre urología - Información para pacientes',
    handle: 'patientsEs',
    group: 'patients',
    locale: 'es',
    language: 'Español (ES)',
    seo: {
        prepend: '- Información para pacientes',
        description: 'La información para pacientes de la EAU es su portal de información confiable sobre urología y salud urológica: cánceres urológicos, otras enfermedades, pruebas y tratamientos.',
    },
} as SiteConfig;

const PatientsConfigDe = {
    ...PatientsConfig,
    name: 'Zuverlässige Informationen zur Urologie – Patienteninformationen',
    handle: 'patientsDe',
    group: 'patients',
    locale: 'de',
    language: 'Deutsch (DE)',
    seo: {
        prepend: '- Patienteninformationen',
        description: 'EAU-Patienteninformationen ist Ihr Portal zu zuverlässigen Informationen zur Urologie und urologischen Gesundheit: urologische Krebserkrankungen, andere Krankheiten, Tests und Behandlungen.',
    },
} as SiteConfig;

const PatientsConfigFr = {
    ...PatientsConfig,
    name: 'Informations fiables sur l\'urologie - Informations destinées aux patients',
    handle: 'patientsFr',
    group: 'patients',
    locale: 'fr',
    language: 'Français (FR)',
    seo: {
        prepend: '- Informations destinées aux patients',
        description: 'EAU Patient Information est votre portail vers des informations fiables sur l\'urologie et la santé urologique : cancers urologiques, autres maladies, tests et traitements.',
    },
} as SiteConfig;

const PatientsConfigAr = {
    ...PatientsConfig,
    name: 'معلومات موثوقة حول الأمراض البولية - معلومات المرضى',
    handle: 'patientsAr',
    group: 'patients',
    locale: 'ar',
    language: 'العربية (AR)',
    rtl: true,
    seo: {
        prepend: '- معلومات المرضى',
        description: 'معلومات المرضى في الاتحاد الأوروبي هو بوابتك إلى معلومات موثوقة حول الأمراض البولية وصحة الجهاز البولي: الأورام البولية، وغيرها من الأمراض، والاختبارات والعلاجات.',
    },
} as SiteConfig;

const PatientsConfigEl = {
    ...PatientsConfig,
    name: 'Αξιόπιστες πληροφορίες για την ουρολογία - Πληροφορίες ασθενών',
    handle: 'patientsEl',
    group: 'patients',
    locale: 'el',
    language: 'Ελληνικά (GR)',
    seo: {
        prepend: '- Πληροφορίες Ασθενούς',
        description: 'Η EAU Patient Information είναι η πύλη σας για αξιόπιστες πληροφορίες σχετικά με την ουρολογία και την ουρολογική υγεία: ουρολογικοί καρκίνοι, άλλες ασθένειες, εξετάσεις και θεραπείες.',
    },
} as SiteConfig;

const PatientsConfigPt = {
    ...PatientsConfig,
    name: 'Informações fidedignas sobre urologia - Informações do paciente',
    handle: 'patientsPt',
    group: 'patients',
    locale: 'pt',
    language: 'Português (PT)',
    seo: {
        prepend: '- Informações do doente',
        description: 'EAU Patient Information é o seu portal para informações fiáveis ​​sobre urologia e saúde urológica: cancro urológico, outras doenças, testes e tratamentos.',
    },
} as SiteConfig;

const PatientsConfigSl = {
    ...PatientsConfig,
    name: 'Zanesljive informacije o urologiji - Informacije za bolnike',
    handle: 'patientsSl',
    group: 'patients',
    locale: 'sl',
    language: 'Slovenščina (SL)',
    seo: {
        prepend: '- Informacije o bolniku',
        description: 'EAU Patient Information je vaš portal za zanesljive informacije o urologiji in urološkem zdravju: urološki raki, druge bolezni, testi in zdravljenja.',
    },
} as SiteConfig;

const PatientsConfigTr = {
    ...PatientsConfig,
    name: 'Üroloji hakkında güvenilir bilgiler - Hasta Bilgileri',
    handle: 'patientsTr',
    group: 'patients',
    locale: 'tr',
    language: 'Türkçe (TR)',
    seo: {
        prepend: '- Hasta Bilgileri',
        description: 'EAU Hasta Bilgileri, üroloji ve üroloji sağlığı hakkında güvenilir bilgilere ulaşabileceğiniz portalınızdır: ürolojik kanserler, diğer hastalıklar, testler ve tedaviler.',
    },
} as SiteConfig;

const PatientsConfigCn = {
    ...PatientsConfig,
    name: '泌尿科的可靠信息 - 患者信息',
    handle: 'patientsCn',
    group: 'patients',
    locale: 'cn',
    language: '中國 (CN)',
    seo: {
        prepend: '- 患者信息',
        description: 'EAU 患者信息是您获取泌尿科和泌尿科健康可靠信息的门户：泌尿科癌症、其他疾病、检查和治疗。',
    },
} as SiteConfig;

const PatientsConfigIt = {
    ...PatientsConfig,
    name: 'Informazioni affidabili sull\'urologia - Informazioni per i pazienti',
    handle: 'patientsIt',
    group: 'patients',
    locale: 'it',
    language: 'Italiano (IT)',
    seo: {
        prepend: '- Informazioni per i pazienti',
        description: 'EAU Patient Information è il tuo portale per informazioni affidabili sull\'urologia e sulla salute urologica: tumori urologici, altre malattie, test e trattamenti.',
    },
} as SiteConfig;

const PatientsConfigLv = {
    ...PatientsConfig,
    name: 'Uzticama informācija par uroloģiju – informācija par pacientu',
    handle: 'patientsLv',
    group: 'patients',
    locale: 'lv',
    language: 'Latviski (LV)',
    seo: {
        prepend: '- Informācija par pacientu',
        description: 'EAU Patient Information ir jūsu portāls uz uzticamu informāciju par uroloģiju un uroloģijas veselību: uroloģiskiem vēža veidiem, citām slimībām, testiem un ārstēšanu.',
    },
} as SiteConfig;

const PatientsConfigFi = {
    ...PatientsConfig,
    name: 'Luotettavaa tietoa urologiasta - Potilastiedot',
    handle: 'patientsFi',
    group: 'patients',
    locale: 'fi',
    language: 'Suomi (FI)',
    seo: {
        prepend: '- Potilastiedot',
        description: 'EAU Patient Information on portaalisi luotettavaan tietoon urologiasta ja urologian terveydestä: urologisista syövistä, muista sairauksista, testeistä ja hoidoista.',
    },
} as SiteConfig;

const PatientsConfigCz = {
    ...PatientsConfig,
    name: 'Spolehlivé informace o urologii - Informace pro pacienty',
    handle: 'patientsCz',
    group: 'patients',
    locale: 'cz',
    language: 'Čeština (CZ)',
    seo: {
        prepend: '- Informace pro pacienta',
        description: 'EAU Patient Information je váš portál ke spolehlivým informacím o urologii a urologickém zdraví: urologická rakovina, jiná onemocnění, testy a léčby.',
    },
} as SiteConfig;

const PatientsConfigSk = {
    ...PatientsConfig,
    name: 'Spoľahlivé informácie o urológii - Informácie pre pacienta',
    handle: 'patientsSk',
    group: 'patients',
    locale: 'sk',
    language: 'Slovenčina (SK)',
    seo: {
        prepend: '- Informácie pre pacienta',
        description: 'EAU Patient Information je váš portál k spoľahlivým informáciám o urológii a urologickom zdraví: urologické rakoviny, iné choroby, testy a liečby.',
    },
} as SiteConfig;

const PatientsConfigPl = {
    ...PatientsConfig,
    name: 'Wiarygodne informacje na temat urologii - Informacje dla pacjentów',
    handle: 'patientsPl',
    group: 'patients',
    locale: 'pl',
    language: 'Polski (PL)',
    seo: {
        prepend: '- Informacje dla pacjentów',
        description: 'EAU Patient Information to Twój portal do wiarygodnych informacji na temat urologii i zdrowia urologicznego: nowotworów urologicznych, innych chorób, badań i metod leczenia.',
    },
} as SiteConfig;

const PatientsConfigHr = {
    ...PatientsConfig,
    name: 'Pouzdane informacije o urologiji - Informacije za pacijente',
    handle: 'patientsHr',
    group: 'patients',
    locale: 'hr',
    language: 'Hrvatski (HR)',
    seo: {
        prepend: '- Podaci o pacijentu',
        description: 'EAU Patient Information vaš je portal za pouzdane informacije o urologiji i urološkom zdravlju: urološki karcinomi, druge bolesti, testovi i tretmani.',
    },
} as SiteConfig;

const PatientsConfigBg = {
    ...PatientsConfig,
    name: 'Надеждна информация за урологията - Информация за пациента',
    handle: 'patientsBg',
    group: 'patients',
    locale: 'bg',
    language: 'Български (BG)',
    seo: {
        prepend: '- Информация за пациента',
        description: 'EAU Patient Information е вашият портал за надеждна информация за урологията и урологичното здраве: урологични ракови заболявания, други заболявания, тестове и лечения.',
    },
} as SiteConfig;

const PatientsConfigAl = {
    ...PatientsConfig,
    name: 'Informacion i besueshëm mbi urologjinë - Informacioni i pacientit',
    handle: 'patientsAl',
    group: 'patients',
    locale: 'al',
    language: 'Shqip (AL)',
    seo: {
        prepend: '- Informacioni i pacientit',
        description: 'Informacioni i Pacientëve të EAU është portali juaj për informacion të besueshëm mbi shëndetin urologjik dhe urologjik: kanceret urologjike, sëmundje të tjera, teste dhe trajtime.',
    },
} as SiteConfig;

const PatientsConfigHu = {
    ...PatientsConfig,
    name: 'Megbízható információk az urológiáról - Betegtájékoztató',
    handle: 'patientsHu',
    group: 'patients',
    locale: 'hu',
    language: 'Magyar nyelv (HU)',
    seo: {
        prepend: '- Betegtájékoztató',
        description: 'Az EAU Patient Information az Ön portálja az urológiával és az urológiai egészséggel kapcsolatos megbízható információkhoz: urológiai rákok, egyéb betegségek, vizsgálatok és kezelések.',
    },
} as SiteConfig;

const PatientsConfigEe = {
    ...PatientsConfig,
    name: 'Usaldusväärne teave uroloogia kohta – patsienditeave',
    handle: 'patientsEe',
    group: 'patients',
    locale: 'ee',
    language: 'Eesti keel (EE)',
    seo: {
        prepend: '- Patsiendi teave',
        description: 'EAU Patient Information on teie portaal usaldusväärse teabe saamiseks uroloogia ja uroloogia tervise kohta: uroloogilised vähid, muud haigused, testid ja ravi.',
    },
} as SiteConfig;

export {
    PatientsConfig,
    PatientsConfigNl,
    PatientsConfigEs,
    PatientsConfigDe,
    PatientsConfigFr,
    PatientsConfigAr,
    PatientsConfigEl,
    PatientsConfigPt,
    PatientsConfigSl,
    PatientsConfigTr,
    PatientsConfigCn,
    PatientsConfigIt,
    PatientsConfigLv,
    PatientsConfigFi,
    PatientsConfigCz,
    PatientsConfigSk,
    PatientsConfigPl,
    PatientsConfigHr,
    PatientsConfigBg,
    PatientsConfigAl,
    PatientsConfigHu,
    PatientsConfigEe
};
